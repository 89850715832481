@tailwind base;
@tailwind components;
@tailwind utilities;

/* Here you can place any CSS variables */
:root {
  --products: 4;
  --header-padding: 210px;
}

p,
span {
  @apply text-12-20-sg;
}

strong,
b {
  @apply font-medium;
}

.center-justified {
  text-align: justify;
  text-align-last: center;
}

.wobble-children * {
  color: currentColor;
}

.force-show-reach-menu {
  display: block !important;
}

.facebook-icon:hover path {
  @apply text-facebook transition-colors;
}

.instagram-icon:hover path {
  @apply text-instagram transition-colors;
}

.youtube-icon:hover path {
  @apply text-youtube transition-colors;
}

.pinterest-icon:hover path {
  @apply text-pinterest transition-colors;
}

/* Chrome, Safari, Edge, Opera */
input.cart-number-input::-webkit-outer-spin-button,
input.cart-number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'].cart-number-input {
  -moz-appearance: textfield;
}

.button {
  @apply flex w-full justify-center py-4 text-p;
}

[type='radio'] {
  transition: transform 250ms ease;
  height: 20px;
  width: 20px;
}

[type='radio']:checked,
[type='radio']:not(:checked) {
  color: black;
  background-color: white;
  border: 1px solid black;
}

[type='checkbox']:focus,
[type='radio']:focus {
  outline: none !important;
  outline-color: transparent;
  box-shadow: none !important;
}

[type='radio']:checked {
  position: relative;
  border: 1px solid black;
}

[type='radio']:checked::after {
  background-color: black;
  border: 1px solid black;
  width: 14px;
  height: 14px;
  border-radius: 14px;
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

[type='radio']:checked:hover,
[type='radio']:checked:focus {
  background-color: transparent;
  border: 1px solid black;
}

[type='checkbox'] {
  width: 20px;
  height: 20px;
}

[type='checkbox']:checked {
  border: 1px solid black;
}

[type='checkbox']:checked {
  color: black;
}

[type='checkbox']:checked + label::before {
  box-shadow: inset 0px 0px 0px 3px #fff;
  background-color: #8cad2d;
}

/* Solves a bug in the react query dev tools with mac dark theme */
.ReactQueryDevtoolsPanel * {
  color: #fff !important;
}

[data-reach-menu-item] {
  padding: 0px 24px !important;
  margin: 16px 0 !important;
}

[data-reach-menu-item][data-selected] {
  background: none !important;
  color: #141414 !important;
}

.teaser-visible .swiper-wrapper {
  @apply ease-[cubic-bezier(0.77,0,0.175,1)];
}
.swiper-pagination-bullet.swiper-pagination-bullet {
  @apply !mx-2 h-[6px] w-[6px] bg-grey-dark;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply bg-primary-black;
}
.vh100 {
  height: 100vh;
  height: 100dvh;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
  opacity: 1 !important;
}

.content-width > * {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}
@media all and (min-width: 1920px) {
  .breakout-content-width {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

[data-reach-menu-popover] {
  z-index: 9999;
}

/* Lookbook left & right pages shadow */
.--left {
  @apply md:rounded-tl-[22px] md:rounded-bl-[22px] md:shadow-[inset_-11px_0_30px_-1px_rgb(0,0,0)];
}

.--right {
  @apply md:rounded-tr-[22px] md:rounded-br-[22px] md:shadow-[inset_11px_0_30px_-1px_rgb(0,0,0)];
}

/* Reviews widget styles override */

#ReviewsWidget .ElementsWidget-prefix .u-textCenter--all {
  text-align: left !important;
}

.ElementsWidget-prefix .R-Button {
  transition: all 0.3s ease-out !important;
}

.ElementsWidget-prefix .R-Button--primary:hover {
  background-color: transparent !important;
  border: 1px solid #000000 !important;
  color: #000000 !important;
}

.ElementsWidget-prefix .R-Button--secondary:hover {
  background-color: #000000 !important;
  border: 1px solid #000000 !important;
  color: #ffffff !important;
}

/* Carousel global vars */

[data-role='carousel'] {
  --carousel-slidesPerView: var(--carousel-slidesPerView-base);
  --carousel-arrow-position: 50%;
}

[data-role='carousel-slides']::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
  width: 0;
  height: 0;
}

@media screen(sm) {
  [data-role='carousel'] {
    --carousel-slidesPerView: var(--carousel-slidesPerView-sm);
  }
}

@media screen(md) {
  [data-role='carousel'] {
    --carousel-slidesPerView: var(--carousel-slidesPerView-md);
  }
}

@media screen(lg) {
  [data-role='carousel'] {
    --carousel-slidesPerView: var(--carousel-slidesPerView-lg);
  }
}
@media screen(lgx) {
  [data-role='carousel'] {
    --carousel-slidesPerView: var(--carousel-slidesPerView-lgx);
  }
}

@media screen(xl) {
  [data-role='carousel'] {
    --carousel-slidesPerView: var(--carousel-slidesPerView-xl);
  }
  :root {
    --header-padding: 180px;
  }
}

@media screen(2xl) {
  [data-role='carousel'] {
    --carousel-slidesPerView: var(--carousel-slidesPerView-2xl);
  }
}

/* Overriding Review Widget input classes */
.R-Field__input {
  border-radius: 5px !important;
}

html {
  scrollbar-gutter: stable;
}
